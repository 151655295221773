import { CustomImage } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { Storytailo } from 'shared/images/storytailo';
import { SubscriptionType } from 'shared/types/users';
import { borderMd, cWhite } from 'shared/utils/styleCommon';
import { pxToRem } from 'shared/utils/commonUtils';

export const AdvertBanner = () => {
  const navigate = useNavigate();

  return (
    <div
      className="w-100 h-100"
      style={{
        borderRadius: borderMd,
        maxHeight: pxToRem(470),
        zIndex: 0,
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <CustomImage directory="common" filename="baner_promo_discover.png" skeleton width="100%" />
      <div
        className="d-flex flex-column justify-content-center align-items-center h-100 w-100 p-2"
        style={{ position: 'absolute', zIndex: 1, top: 0 }}
      >
        <div className="pb-3">
          <Storytailo />
        </div>
        <Typography variant="h1" classNames="text-center pb-2" styles={{ color: cWhite }}>
          Odkryj dla siebie magiczny świat bajek
        </Typography>
        <Typography variant="description" classNames="text-center" styles={{ color: cWhite, paddingBottom: pxToRem(32) }}>
          Twórz i rozwijaj jeszcze bardziej spersonalizowane bajki!
        </Typography>
        <Button onClick={() => navigate(paths.subscription.base + `/${SubscriptionType.ADVANCED}`)}>Sprawdź</Button>
      </div>
    </div>
  );
};
