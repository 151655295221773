export interface PredefinedNarrator {
  id?: string;
  value: string;
  image?: string;
  cost: number;
  type: NarratorType;
  order?: number;
}

export enum NarratorType {
  SILENT = 'SILENT',
  NORMAL = 'NORMAL',
  PREMIUM = 'PREMIUM',
  PREMIUM_2 = 'PREMIUM_2',
  ADMIN = 'ADMIN'
}
